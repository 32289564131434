<template>
  <div class="add-new-car">
    <title-bar
      submitBtn
      submitBtnTitle="Update car"
      title="Save car"
      @submitPressed="submitPressed"
    />
    <car-form v-if="car" :carObject="car" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import CarForm from '@/views/Admin/Cars/components/CarForm.vue'
import router from '@/router'

export default {
  components: {
    TitleBar,
    CarForm
  },
  data() {
    return {
      action: null,
      car: null
    }
  },
  created() {
    this.loadCar()
  },
  methods: {
    loadCar() {
      const { id } = router.currentRoute.params
      this.$Cars.getResource({ id }).then(response => {
        this.car = response.data
        if(!this.car.color) {
          this.car.color = { label: 'Not selected', id: null, disabled: true }
        } else {
          this.car.color = { label: this.car.color, id: this.car.color}
        }
         if(!this.car.size) {
          this.car.size = { label: 'Not selected', id: null, disabled: true }
        } else {
          this.car.size = { label: this.$helper.getEnumTranslation('car_size', this.car.size), id: this.car.size}
        }
        if (this.car.courier) {
          this.car.courier = {
            label: `${this.car.courier.givenName} ${this.car.courier.familyName}`,
            id: this.car.courier['@id'],
            numberId: this.car.courier.id,
          }
        } else {
          this.car.courier = { label: 'Not selected', id: null}
        }
      })
    },
    submitPressed() {
      this.action = 'update'
    }
  },
}
</script>
