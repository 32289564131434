var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"carForm",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"row",attrs:{"label":"Name","label-for":"name","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name","vid":"car.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.car.name),callback:function ($$v) {_vm.$set(_vm.car, "name", $$v)},expression:"car.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Courier","label-for":"courier","label-cols-md":"2","content-cols-lg":"4"}},[_c('custom-select',{attrs:{"options":_vm.couriers},model:{value:(_vm.car.courier),callback:function ($$v) {_vm.$set(_vm.car, "courier", $$v)},expression:"car.courier"}})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Registration plate","label-for":"registrationPlate","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Registration plate","vid":"car.registrationPlate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registrationPlate","state":errors.length > 0 ? false : null},model:{value:(_vm.car.registrationPlate),callback:function ($$v) {_vm.$set(_vm.car, "registrationPlate", $$v)},expression:"car.registrationPlate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Description","label-for":"description","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-textarea',{attrs:{"id":"description","name":"Description"},model:{value:(_vm.car.description),callback:function ($$v) {_vm.$set(_vm.car, "description", $$v)},expression:"car.description"}})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Color","label-for":"color","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"selectedValue","name":"Color","vid":"color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"main-dropdown"},[_c('v-select',{staticClass:"custom-select",attrs:{"id":"color","options":_vm.colors,"clearable":false,"searchable":true,"selectable":function (option) { return !option.disabled; },"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [(option.id)?_c('div',{staticClass:"selected-option-color",style:(("background: " + (option.label)))}):_c('div',[_vm._v(" "+_vm._s(option.label)+" ")])]}},{key:"option",fn:function(ref){
var label = ref.label;
var id = ref.id;
return [(id)?_c('div',{staticClass:"selected-option-color",style:(("background: " + label))}):_c('div',[_vm._v(" "+_vm._s(label)+" ")])]}}],null,true),model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Size","label-for":"size","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"selectedValue","name":"Size","vid":"size"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"main-dropdown"},[_c('v-select',{staticClass:"custom-select",attrs:{"id":"size","options":_vm.sizes,"clearable":false,"searchable":true,"selectable":function (option) { return !option.disabled; },"state":errors.length > 0 ? false : null},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }