<template>
  <validation-observer ref="carForm" tag="form" @submit.prevent="onSubmit">
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="row"
            label="Name"
            label-for="name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Name"
              vid="car.name"
            >
              <b-form-input
                v-model="car.name"
                id="name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Courier"
            label-for="courier"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <custom-select v-model="car.courier" :options="couriers" />
          </b-form-group>

          <b-form-group
            class="row"
            label="Registration plate"
            label-for="registrationPlate"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Registration plate"
              vid="car.registrationPlate"
            >
              <b-form-input
                v-model="car.registrationPlate"
                id="registrationPlate"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Description"
            label-for="description"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-textarea
              id="description"
              name="Description"
              v-model="car.description"
            />
          </b-form-group>

          <b-form-group
            class="row"
            label="Color"
            label-for="color"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="selectedValue"
              name="Color"
              vid="color"
            >
              <div class="main-dropdown">
                <v-select
                  v-model="color"
                  id="color"
                  :options="colors"
                  class="custom-select"
                  :clearable="false"
                  :searchable="true"
                  :selectable="(option) => !option.disabled"
                  :state="errors.length > 0 ? false : null"
                >
                  <template slot="selected-option" slot-scope="option">
                    <div
                      v-if="option.id"
                      class="selected-option-color"
                      :style="`background: ${option.label}`"
                    ></div>
                    <div v-else>
                      {{ option.label }}
                    </div>
                  </template>
                  <template #option="{ label, id }">
                    <div
                      v-if="id"
                      class="selected-option-color"
                      :style="`background: ${label}`"
                    ></div>
                    <div v-else>
                      {{ label }}
                    </div>
                  </template>
                </v-select>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Size"
            label-for="size"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="selectedValue"
              name="Size"
              vid="size"
            >
              <div class="main-dropdown">
                <v-select
                  v-model="size"
                  id="size"
                  :options="sizes"
                  class="custom-select"
                  :clearable="false"
                  :searchable="true"
                  :selectable="(option) => !option.disabled"
                  :state="errors.length > 0 ? false : null"
                >
                </v-select>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Toast from '@/components/Toast.vue'
import CustomSelect from '@/components/Inputs/CustomSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    CustomSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    carObject: { Object, required: true },
    action: { type: String, default: null },
  },
  data() {
    return {
      car: this.carObject,
      couriers: [{ label: 'Not selected', id: null }],
      colors: [{ label: 'Not selected', id: null, disabled: true }],
      sizes: [{ label: 'Not selected', id: null, disabled: true }],
      color: this.carObject.color,
      size: this.carObject.size,
    }
  },
  watch: {
    action() {
      this.onSubmit()
    },
  },
  async created() {
    this.$Enums
      .getResourceByUrl({ url: '/enums/car_color' })
      .then(response => {
        response.data.keys.forEach(element => {
          this.colors.push({
            label: element,
            id: element,
          })
        })
      })

    this.$Enums
      .getResourceByUrl({ url: '/enums/car_size' })
      .then(response => {
        response.data.keys.forEach(element => {
          this.sizes.push({
            label: this.$helper.getEnumTranslation('car_size', element),
            id: element,
          })
        })
      })

    const headers = {
      fields:
        '"/hydra:member/*/@id", "/hydra:member/*/id", "/hydra:member/*/givenName",  "/hydra:member/*/familyName", "/hydra:member/*/fileUrl"',
    }
    this.$Users
      .getCollection({
        headers,
        params: { pagination: false, role: 'ROLE_COURIER' },
      })
      .then(response => {
        response.data['hydra:member'].forEach(courier => {
          this.couriers.push({
            label: `${courier.givenName} ${courier.familyName}`,
            id: courier['@id'],
            numberId: courier.id,
          })
        })
      })
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.carForm.validate()
      if (isValid) {
        if (this.car.courier) {
          this.car.courier = this.car.courier.id
        }
        if (this.color) {
          this.car.color = this.color.id
        }
        if (this.size) {
          this.car.size = this.size.id
        }
        if (this.action === 'add') {
          this.$Cars
            .createResource({ body: this.car })
            .then(response => {
              if (response.status === 201) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'New car created',
                  '',
                  'success',
                )
                this.$router.push(`/cars`)
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  error.response.data['hydra:title'],
                  this.car.courier
                    ? 'Make sure that courier is not assigned to another car'
                    : error.response.data['hydra:description'],
                  'danger',
                )
              }
            })
        }

        if (this.action === 'update') {
          this.$Cars
            .updateResource({ id: this.car.id, body: this.car })
            .then(response => {
              if (response.status === 200) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  'Car updated',
                  '',
                  'success',
                )
                this.$router.push(`/cars`)
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  error.response.data['hydra:title'],
                  this.car.courier
                    ? 'Make sure that courier is not assigned to another car'
                    : error.response.data['hydra:description'],
                  'danger',
                )
              }
            })
        }
      } else {
        this.$emit('clearAction')
      }
    },
  },
}
</script>

,
                        